























import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";

export interface IHandoverPeriod {
  periodFrom: string;
  periodTo: string;
}

export default defineComponent({
  name: "AddApproveModal",
  components: {
    DatePicker,
    TextFilter,
  },
  data() {
    return {
      fullName: "",
    };
  },
  props: {
    cbOnAddApprove: {
      type: Function,
      default: () => 1,
    },
    props: {
      type: Object,
      default: () => ({ id: "" }),
    },
    isMainPerson: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    addApproveCb() {
      (this as { cbOnAddApprove: (fullName: string) => void }).cbOnAddApprove?.(this.fullName);
      this.closeModal();
    },
  },
});
